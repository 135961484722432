.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.errorImg {
  width: 300px;
  margin-bottom: 40px;
}
